import { SplineViewer } from "@splinetool/viewer"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import LocomotiveScroll from "locomotive-scroll"
import { renderSquircle } from "corner-smoothing"
import BlurPlugin from "../src/BlurPlugin.js"

/**
 * Setup
 */

gsap.registerPlugin(BlurPlugin)
gsap.registerPlugin(ScrollTrigger)

const locomotiveScroll = new LocomotiveScroll()

/**
 * Sizes
 */

const body = document.body;
const html = document.documentElement;

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
    aspectRatio: window.innerWidth / window.innerHeight,
    allHeight: Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
}

window.addEventListener("resize", () => {

    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    sizes.aspectRatio = window.innerWidth / window.innerHeight
    sizes.allHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    // Update responsiveness
    updateDescriptionMarginTop()
    updateEmailSubHeight()

    // Update squircles (smooth corners)
    updateSquircles()
})

/**
 * Loading Timeline
 */

// Runs as soon as possible. Uses only static html data. 'defer'attribute in a <script> tag insures that script is executed only after the entire document has been parsed
setWeather() // Fetches data from API
let timeInterval
setTime(new Date)

// Create a promise for the 'DOMContentLoaded' event
const DOMContentLoaded = new Promise((resolve) => {
    document.addEventListener("DOMContentLoaded", resolve)
})

const header = document.querySelector("header")
const footer = document.querySelector("footer")

// DOMContentLoaded event fired + custom header and footer components have been rendered
Promise.all([DOMContentLoaded, header.rendered, footer.rendered]).then(() => {

    // Responsiveness
    updateDescriptionMarginTop()
    updateEmailSubHeight()

    // Copy email logic
    copyEmail()

    // Draw squircles (smooth corners)
    updateSquircles()

    // Animation
    rotationAnimation() // Scroll accelerated rotation animation: CTA and dividers
    magneticEffect() // Magnetic effect: CTA
})

// Reveal model animation when spline has been loaded
const mySplineViewer = document.querySelector("spline-viewer")
mySplineViewer.addEventListener("load-complete", () => {

    // Spline frame (3D model) animation
    gsap.fromTo
        (
            mySplineViewer.shadowRoot.getElementById("container"),
            { opacity: 0, scale: 0.8, blur: 20 },
            { opacity: 1, scale: 1, blur: 0, delay: 0.56, duration: 0.4, ease: "circ.out" }
        )
})

/**
* Email input handling
**/

const inputEmail = document.querySelector("input[type='email']")
const form = document.querySelector("form")

// Focus/blur states
inputEmail.addEventListener("focus", () => {
    form.style.background = "linear-gradient(180deg, #59585740, 64%, #59585700)"
})
inputEmail.addEventListener("blur", () => {
    form.style.background = "linear-gradient(180deg, #59585716, 64%, #59585700)"
})

// Invalid shaking
inputEmail.addEventListener("invalid", () => {
    // gsap.effects.wobble("form", { duration: 0.1})
})

// Submiting email to MailerLite (if pases html type="email" validation) 
// and showing success message
form.addEventListener("submit", (e) => {
    e.preventDefault()
    const requestBody = {
        email: inputEmail.value
    }

    fetch("https://2qtt2ujcsk.execute-api.eu-north-1.amazonaws.com/prod/mailer", {
        method: "POST",
        body: JSON.stringify(requestBody)
    })
        .then(response => response.json())
        .then(data => {
            // handle the successful response
            // console.log(data)
            gsap.to(form, { y: 32, ease: "circ.inOut", rotateX: 90, opacity: 0, duration: 0.64 })
            gsap.to(".form-success", { y: 32, ease: "circ.inOut", rotateX: 0, opacity: 100, duration: 0.64 })
        })
        .catch(error => {
            // handle the error response
            console.error('Error:', error)
        })
})

/**
* Case Hovers
**/
const miniCases = document.querySelectorAll("a.border")

miniCases.forEach((miniCase) => {
    const tags = miniCase.querySelector(".tags")
    const overlay = miniCase.querySelector(".overlay")
    const text = miniCase.querySelectorAll(".overlay > *")

    const overAnim = gsap.timeline()
    overAnim.fromTo(overlay, { opacity: 0, y: vwToPx(45 * 3 / 4) }, { opacity: 1, y: 0, duration: 0.4, ease: "power4.out" }, 0.04).pause()
    // overAnim.fromTo(caseVideo, {scale: 1}, {scale: 1.04, duration: 8, ease: "none"}, "<").pause()
    overAnim.fromTo(tags, { opacity: 0, y: vwToPx(-4) }, { opacity: 1, y: 0, duration: 0.64, ease: "back.out(0.6)" }, 0.16).pause()
    overAnim.fromTo(text, { y: vwToPx(5.6) }, { y: 0, duration: 0.64, ease: "back.out(0.5)" }, 0.04).pause()
    overAnim.fromTo(text, { opacity: 0 }, { opacity: 1, duration: 0.64, ease: "power2.inOut" }, "<").pause()

    const leaveAnim = gsap.timeline()
    leaveAnim.to(overlay, { opacity: 0, duration: 0.8, ease: "power4.out" }, 0).pause()
    // leaveAnim.to(caseVideo, {scale: 1, duration: 0.8, ease: "power1.out"}, "<").pause()
    leaveAnim.to(tags, { opacity: 0, duration: 0.32, ease: "back.out(0.4)" }, "<").pause()
    leaveAnim.to(text, { opacity: 0, duration: 0.32, ease: "back.out(0.4)" }, "<").pause()

    miniCase.addEventListener("mouseover", () => {
        leaveAnim.pause()
        overAnim.restart()
    })
    miniCase.addEventListener("focus", () => {
        if (!miniCase.matches(':hover')) {
            leaveAnim.pause()
            overAnim.restart()
        }
    })

    miniCase.addEventListener("mouseleave", () => {
        overAnim.pause()
        leaveAnim.restart()
    })
    miniCase.addEventListener("blur", () => {
        overAnim.pause()
        leaveAnim.restart()
    })
})

/**
 * Functions
 */

// Scroll accelerated rotation animation: CTA and dividers
// Adapted solution from: https://codepen.io/cameronknight/pen/oNGEvmw
function rotationAnimation() {
    const additionalRotation = { val: 0 }
    let additionalRotationAnim
    let offset = 0

    const scrollerTrigger = ScrollTrigger.create({
        trigger: "html",
        start: "top",
        end: "bottom",
        onUpdate: function (self) {
            const velocity = self.getVelocity();
            if (velocity > 0) {
                if (additionalRotationAnim) additionalRotationAnim.kill();
                additionalRotation.val = -velocity / 600;
                additionalRotationAnim = gsap.to(additionalRotation, { val: 0 });
            }
            if (velocity < 0) {
                if (additionalRotationAnim) additionalRotationAnim.kill();
                additionalRotation.val = -velocity / 900;
                additionalRotationAnim = gsap.to(additionalRotation, { val: 0 });
            }
        }
    })

    const curvedTextTween = gsap.to(
        ".rotation-animation",
        {
            rotation: "-=" + 360,
            duration: 16,
            repeat: -1,
            ease: "none",
            modifiers: {
                rotation: gsap.utils.unitize((rotation) => {
                    offset += additionalRotation.val;
                    rotation = (parseFloat(rotation) + offset) % 360;
                    return rotation;
                })
            }
        }
    )
}

// Magnetic effect: CTA
// Adapted solution from: https://codepen.io/GreenSock/pen/yLOmYgV
function magneticEffect() {

    function parallaxIt(e, wrap, movement = 1) {
        var scrollTop = window.scrollY || document.documentElement.scrollTop;
        var boundingRect = wrap.mArea.getBoundingClientRect();
        var relX = e.pageX - boundingRect.left
        var relY = e.pageY - boundingRect.top

        gsap.to(wrap.mContent, {
            x: (relX - boundingRect.width / 2) * movement,
            y: (relY - boundingRect.height / 2 - scrollTop) * movement,
            ease: "sine.out",
            duration: 0.8
        })
    }

    const allWraps = document.querySelectorAll(".contact-and-cta")

    allWraps.forEach(wrap => {
        wrap.mContent = wrap.querySelector(".call-to-action")
        wrap.mArea = wrap.querySelector(".curved-text")

        wrap.mArea.addEventListener("mousemove", function (e) {
            parallaxIt(e, wrap)
        })

        wrap.mArea.addEventListener("mouseleave", function (e) {
            gsap.to(wrap.mContent, {
                scale: 1,
                x: 0,
                y: 0,
                ease: "elastic.out(1,0.75)",
                duration: 0.8
            })
        })
    })
}

// Make first screen responsive
function updateDescriptionMarginTop() {
    const header = document.querySelector("header")
    const sectionDescription = document.querySelector("section.description")

    const pxToVh = (header.offsetTop + header.offsetHeight + sectionDescription.offsetHeight) * 100 / sizes.height
    // NOT SURE ABOU 4.4 AS A CASE PARTS
    const vwToVh = (4 + 4.4) * sizes.aspectRatio // 4 sectionDescription.style.marginBottom, 4.4 case parts

    const descriptionMarginTop = 100 - pxToVh - vwToVh
    sectionDescription.style.marginTop = descriptionMarginTop + "vh"
}

// Make last screen responsive
function updateEmailSubHeight() {
    const sectionEmailSub = document.querySelector("section.email-sub")
    const footer = document.querySelector("footer")

    const pxToVh = (document.documentElement.scrollHeight - footer.offsetTop) * 100 / sizes.height // footer + body.marginBottom
    // NOT SURE ABOU 4.4 AS A CASE PARTS
    const vwToVh = (4.4 + 2.2 + 2.2) * sizes.aspectRatio // 4.4 case parts, 2.2 cases.marginBottom, 2.2 emalSub.marginBottom

    const emailSubHeight = 100 - pxToVh - vwToVh
    sectionEmailSub.style.height = emailSubHeight + "vh"
}

// Set Weather
function setWeather() {

    fetch("https://2qtt2ujcsk.execute-api.eu-north-1.amazonaws.com/prod/weather")
        .then(response => response.json())
        .then(data => {
            const spanTemperature = document.querySelector("span.temperature")
            spanTemperature.textContent = data.current.tempFormatted
            // console.log("Code: " + data.current.condition.code)
            // console.log("Text: " + data.current.condition.text)
        })
        .catch(error => {
            // Hide container if no temp data returned
            const divWeather = document.querySelector("div.weather")
            divWeather.style.display = "none"
            // console.error("Error:", error)
        })
}

// Set Time
// Adapted solution from: https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
function setTime(dateObject) {
    const pTime = document.querySelector("p.time")
    let date = dateObject.getUTCDate()
    let month = dateObject.getUTCMonth()
    let year = dateObject.getFullYear()
    let hours = dateObject.getUTCHours()

    // If BST — adding 1 hour, from https://en.wikipedia.org/wiki/British_Summer_Time
    switch (month) {

        // March
        case 2:
            if (year == 2024 && date >= 31 ||
                year == 2025 && date >= 30 ||
                year == 2026 && date >= 29 ||
                year == 2027 && date >= 28) {
                hours++
            }
            break

        // April — September
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
            hours++
            break

        // October
        case 9:
            if (year == 2024 && date <= 27 ||
                year == 2025 && date <= 26 ||
                year == 2026 && date <= 25 ||
                year == 2027 && date <= 31) {
                hours++
            }
            break
    }
    let minutes = dateObject.getUTCMinutes()
    let ampm = hours >= 12 ? "pm" : "am"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes
    pTime.textContent = hours + ":" + minutes + " " + ampm
    clearInterval(timeInterval)
    timeInterval = setInterval(setTime, 10000, new Date)
}

// Copy email link logic
function copyEmail() {
    const copyEmailLinks = document.querySelectorAll("a.copy-email")

    copyEmailLinks.forEach(copyEmailLink => {
        copyEmailLink.addEventListener("click", (e) => {
            e.preventDefault()
            navigator.clipboard.writeText("victor@gned.in")
            console.log("Copied")
        })
    })
}

// Draw squircles (smooth corners)
function updateSquircles() {

    const borderWidth = 1 // in px
    const borderCornerRadius = 2.2 * sizes.width / 100 // 2.2vw to px conversion
    const contentCornerRadius = borderCornerRadius - borderWidth

    // Cases
    const caseBorders = document.querySelectorAll(".border")
    const caseContents = document.querySelectorAll(".content")

    caseBorders.forEach(caseBorder => {
        renderSquircle(caseBorder, {
            cornerRadius: borderCornerRadius,
            cornerSmoothing: 0.6,
            preserveSmoothing: true
        })
    });

    caseContents.forEach(caseContent => {
        renderSquircle(caseContent, {
            cornerRadius: contentCornerRadius,
            cornerSmoothing: 0.6,
            preserveSmoothing: true
        })
    });

    // Email Subscription
    const emailSubSquircle = document.querySelector(".email-sub")

    renderSquircle(emailSubSquircle, {
        cornerRadius: borderCornerRadius,
        cornerSmoothing: 0.6,
        preserveSmoothing: true,
        borderWidth: borderWidth
    })


    // Form squircles: input, buttom
    const formSquircles = document.querySelectorAll(".form-squircle")
    const formCornerRadius = 2.8 * sizes.width / 100 // 2.8vw to px conversion
    formSquircles.forEach((formSquircle) => {
        renderSquircle(formSquircle, {
            cornerRadius: formCornerRadius,
            cornerSmoothing: 1,
            borderWidth: borderWidth
        }, formSquircle.classList[0] + "1")
    })
}

// NOT USED
// Shake animation (for invalid form)
gsap.registerEffect({
    name: "wobble",
    effect: (targets, config) => {
        var tl = gsap.timeline()

        tl.to(targets, { duration: config.duration, x: "+=" + 20 })
        tl.to(targets, { duration: config.duration * 2, x: "-=" + 40 }, ">")
        tl.to(targets, { duration: config.duration, x: "+=" + 20 }, ">")

        return tl.play()
    },
    defaults: { duration: 2 }, //defaults get applied to any "config" object passed to the effect
    extendTimeline: true //now you can call the effect directly on any GSAP timeline to have the result immediately inserted in the position you define (default is sequenced at the end)
});

// vw to px conversion
function vwToPx(val) {
    return Math.round(sizes.width * val / 100)
}