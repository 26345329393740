/*!
 * BlurPlugin 3.11.6
 * https://greensock.com
 *
 * @license Copyright 2008-2023, GreenSock. All rights reserved.
 * Subject to the terms at https://greensock.com/standard-license or for
 * Club GreenSock members, the agreement issued with that membership.
 * @author: Jack Doyle, jack@greensock.com
*/

/* eslint-disable */
var gsap,
    _coreInitted,
    _blurProperty,
    _windowExists = function _windowExists() {
  return typeof window !== "undefined";
},
    _getGSAP = function _getGSAP() {
  return gsap || _windowExists() && (gsap = window.gsap) && gsap.registerPlugin && gsap;
},
    _initCore = function _initCore(core) {
  gsap = core || _getGSAP();

  if (gsap) {
    _blurProperty = gsap.utils.checkPrefix("filter");
    _coreInitted = 1;
  }
},
    _blurExp = /blur\((.+)?px\)/,
    _getBlurMatch = function _getBlurMatch(target) {
  return (gsap.getProperty(target, _blurProperty) || "").match(_blurExp) || [];
};

export var BlurPlugin = {
  version: "3.11.6",
  name: "blur",
  get: function get(target) {
    return +_getBlurMatch(target)[1] || 0;
  },
  init: function init(target, endValue) {
    _coreInitted || _initCore();

    var data = this,
        filter = gsap.getProperty(target, _blurProperty),
        endBlur = "blur(" + endValue + "px)",
        match = _getBlurMatch(target)[0],
        index;

    if (filter === "none") {
      filter = "";
    }

    if (match) {
      index = filter.indexOf(match);
      endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
    } else {
      endValue = filter + endBlur;
      filter += filter ? " blur(0px)" : "blur(0px)";
    }

    data.target = target;
    data.interp = gsap.utils.interpolate(filter, endValue);
  },
  render: function render(progress, data) {
    data.target.style[_blurProperty] = data.interp(progress);
  },
  register: _initCore
};
_getGSAP() && gsap.registerPlugin(BlurPlugin);
export { BlurPlugin as default };