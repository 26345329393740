import "@ungap/custom-elements"

const HeaderFooterHTML = `
  <section class="bio">
      <h5 class="letter-spacing">Vic Gnedin</h5>
      <div class="bio-line">
          <p>Senior<sup> plus</sup> Product Designer&thinsp;<sup>8&thinsp;yrs</sup></p>
          <img class="icon rotation-animation" src="./icons/star.svg" draggable="false" alt="Decorative divider icon styled as an asterisk">
          <p>Mentor&thinsp;<sup>4&thinsp;yrs</sup></p>
      </div>
      <div class="bio-line">
          <p>Global Talent</p>
          <img class="icon rotation-animation" src="./icons/star.svg" draggable="false" alt="Decorative divider icon styled as an asterisk">
          <p>Apple Developer Academy&thinsp;<sup>alum</sup></p>
      </div>
  </section>
  <div class="contact-and-cta">
      <section class="contact">
          <h5 class="letter-spacing">A click away</h5>
          <a class="bio-line link" href="https://www.linkedin.com/in/victorgnedin/" target="_blank">
              <div class="sq top-left"></div>
              <div class="sq top-right"></div>
              <div class="sq bottom-right"></div>
              <div class="sq bottom-left"></div>
              <p>linkedin.com/in/victorgnedin</p>
              <img class="icon" src="./icons/link.svg" draggable="false" alt="External link icon showing a square with a center-originating arrow pointing to the top-right corner">
          </a>
          <a class="bio-line link copy-email" href="copy_email">
              <div class="sq top-left"></div>
              <div class="sq top-right"></div>
              <div class="sq bottom-right"></div>
              <div class="sq bottom-left"></div>
              <p>victor@gned.in</p>
              <img class="icon" src="./icons/copy.svg" draggable="false" alt="Clipboard copy action icon depicted by overlapping document shapes">
          </a>
      </section>
      <a class="call-to-action" href="./cv.pdf" target="_blank">
          <h5 class="letter-spacing">CV</h5>
          <img class="curved-text rotation-animation" src="./icons/curved_text.svg" draggable="false" alt="'Download CV' button designed with circular text 'seeking new challenges' and a bold 'CV' at the center">
      </a>
  </div>
  `

class Header extends HTMLElement {
  constructor() {
    super()
    this.rendered = new Promise((resolve) => {
      this.resolveRender = resolve;
    })
  }

  connectedCallback() {
    this.innerHTML = HeaderFooterHTML

    // Resolve the promise when the header is considered rendered
    this.resolveRender()
  }
}

class Footer extends HTMLElement {
  constructor() {
    super()
    this.rendered = new Promise((resolve) => {
      this.resolveRender = resolve;
    })
  }

  connectedCallback() {
    this.innerHTML = HeaderFooterHTML

    // Resolve the promise when the header is considered rendered
    this.resolveRender()
  }
}

customElements.define("reusable-header", Header, { extends: "header" })
customElements.define("reusable-footer", Footer, { extends: "footer" })